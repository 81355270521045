import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { AlertTriangle } from "lucide-react";

const RoomCard = ({
  data,
  roomIndex,
  storeRoomDetails,
  noOfAdult,
  selection,
}) => {
  const sendIndex = (index, addOn, addOnIndex) => {
    storeRoomDetails(index, addOn, addOnIndex);
  };
  const [isRoomAvailable, setIsRoomAvailable] = React.useState(true);

  const initialAvailableRooms =
    data?.availableRooms > 0 ? data.availableRooms : 0;

  const [availableRooms, setAvailableRooms] = React.useState(
    initialAvailableRooms
  );

  useEffect(() => {
    setAvailableRooms(initialAvailableRooms);
  }, [initialAvailableRooms]);

  const basePrice = data?.totalPrice;
  const nights = data?.priceMatrix?.length;
  const noOfRooms = selection?.noOfRooms;

  function openNoAvailableRoomDialog() {
    setIsRoomAvailable(false);
  }

  function closeNoAvailableRoomDialog() {
    setIsRoomAvailable(true);
  }
  const addOnsIndex = data?.addOns?.[0]?.items?.findIndex(
    (i) => i.name === "Rooms Only"
  );

  return (
    <>
      <div className="mb-4 bg-white m-3">
        <div className="row g-0 p-2">
          <div className="col-md-3 py-3 px-1">
            <img
              src={data?.images[0]}
              className="rounded-start"
              alt={data?.name}
              height={180}
              width={290}
              style={{
                objectFit: "cover",
              }}
            />
          </div>
          <div className="col-md-9">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="label fw-bold text-uppercase">{data?.name}</h4>

                {/* <p className="label pat-5">₹ {data?.price}/Night</p> */}
              </div>
              <p className="card-text pat-10">{data?.description}</p>
              <p className="card-text pat-10">
                <small className="label">Size - 185 sq.ft</small>
              </p>

              <div className="pat-10">
                <div className="hotel-view-contents-flex">
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-parking"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-wifi"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-coffee"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-quidditch"></i>{" "}
                    </span>
                  </div>

                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-receipt"></i>{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex mb-3">
                  <span className="me-3">
                    <i className="bi bi-wifi"></i>
                  </span>
                  <span className="me-3">
                    <i className="bi bi-tv"></i>
                  </span>
                  <span className="me-3">
                    <i className="bi bi-air-conditioner"></i>
                  </span>
                </div>
                {/* <div className="btn-wrapper">
                  <Link
                    to="/checkout"
                    className="cmn-btn btn-bg-1 btn-small"
                    onClick={() => sendIndex(roomIndex)}
                  >
                    Book Now
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center px-md-3">
            <div className="col-md-3"></div>
            <div className="col-md-9 col-12 mb-2"></div>
          </div>
          {data?.addOns?.map((item, index) => (
            <Fragment key={index}>
              <div className="d-flex align-items-center px-md-3">
                <div className="col-md-3"></div>
                <div className="col-md-9 col-12 mb-2">
                  <div className="">
                    {item?.items
                      ?.filter(
                        (d) =>
                          d?.name?.trim() === "Rooms Only" ||
                          d?.name?.trim() === "Room Only"
                      )
                      ?.map((filteredItem, i) => (
                        <div className={`py-3 border-bottom`}>
                          <div className="row d-flex align-items-start justify-content-between">
                            <div className="col-md-10">
                              <h6
                                className="label-light pat-5"
                                style={{
                                  textDecoration: "underline",
                                  textUnderlineOffset: "6px",
                                }}
                              >
                                {filteredItem?.name}
                              </h6>
                            </div>
                            <h6 className="label col-md-2 text-md-end mt-3 mt-md-0 text-nowrap">
                              <strong>
                                ₹{" "}
                                {(filteredItem?.price !== 0
                                  ? basePrice +
                                    filteredItem?.price * noOfAdult * nights
                                  : basePrice) * noOfRooms}
                                /-
                              </strong>
                            </h6>
                          </div>

                          <div className="row d-flex align-items-start justify-content-between">
                            <div className="col-md-9">
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: "14px",
                                }}
                              >
                                {filteredItem?.description}
                              </Typography>
                            </div>
                            <div className="col-md-3 d-flex flex-column align-items-start align-items-md-end mt-2 mt-md-0">
                              <p
                                className="text-md-end text-gold"
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {nights} {nights < 2 ? "Night" : "Nights"},{" "}
                                {noOfRooms} {noOfRooms < 2 ? "Room" : "Rooms"}
                                <br /> Excluding taxes and fees
                              </p>
                            </div>
                          </div>

                          <div className="row d-flex align-items-start justify-content-between">
                            <div className="col-md-10"></div>
                            <div className="col-md-2 d-flex justify-content-end mt-1">
                              {availableRooms < 1 ? (
                                <Button
                                  sx={{
                                    textWrap: "nowrap",
                                    border: "1px solid red",
                                    color: "red",
                                  }}
                                  onClick={openNoAvailableRoomDialog}
                                >
                                  Sold Out
                                </Button>
                              ) : (
                                <Link
                                  to={`/checkout?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.startDateTime}&checkout=${selection?.endDateTime}&person=${selection?.noOfAdult}&children=${selection?.noOfChild}&room=${selection?.noOfRooms}`}
                                  className="custom-small-btn text-nowrap"
                                  onClick={() => {
                                    sendIndex(roomIndex, index, addOnsIndex);
                                    sessionStorage.setItem(
                                      "checkoutString",
                                      `?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.startDateTime}&checkout=${selection?.endDateTime}&person=${selection?.noOfAdult}&children=${selection?.noOfChild}&room=${selection?.noOfRooms}`
                                    );
                                  }}
                                >
                                  Book Now
                                </Link>
                              )}

                              {/* <Link
                                to={`/checkout?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.startDateTime}&checkout=${selection?.endDateTime}&person=${selection?.noOfAdult}&children=${selection?.noOfChild}&room=${selection?.noOfRooms}`}
                                className="custom-small-btn text-nowrap"
                                onClick={() =>
                                  sendIndex(
                                    roomIndex,
                                    index,
                                    item?.items?.length - 1
                                  )
                                }
                              >
                                Book Now
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    {/* <p className="text-md"><u>{item?.groupName}</u></p> */}
                    {/* // d?.name?.trim() === "Rooms Only" */}
                    {item?.items?.map((d, i) =>
                      item?.groupName?.trim() === "Occupancy" ||
                      d?.name?.trim() === "Rooms Only" ||
                      d?.name?.trim() === "Room Only" ? null : (
                        <div
                          key={i}
                          className={`py-3 ${
                            i === item.items.length - 1 ? "" : "border-bottom"
                          }`}
                        >
                          <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-md-10">
                              <h6
                                className="label-light pat-5"
                                style={{
                                  textDecoration: "underline",
                                  textUnderlineOffset: "6px",
                                }}
                              >
                                {d?.name}
                              </h6>
                            </div>
                            <h6 className="label col-md-2 text-md-end mt-3 mt-md-0 text-nowrap">
                              <strong>
                                ₹{" "}
                                {d?.perPax
                                  ? (basePrice +
                                      d?.price * noOfAdult * nights) *
                                    noOfRooms
                                  : (basePrice + d?.price * nights) * noOfRooms}
                                /-
                              </strong>
                            </h6>
                          </div>

                          <div className="row d-flex align-items-start justify-content-between">
                            <div className="col-md-9">
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: "14px",
                                }}
                              >
                                {d?.description}
                              </Typography>
                            </div>
                            <div className="col-md-3 d-flex flex-column align-items-start align-items-md-end mt-2 mt-md-0">
                              <p
                                className="text-md-end text-gold"
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "1rem",
                                }}
                              >
                                {nights} {nights < 2 ? "Night" : "Nights"},{" "}
                                {noOfRooms} {noOfRooms < 2 ? "Room" : "Rooms"}
                                <br /> Excluding taxes and fees
                              </p>
                            </div>
                          </div>

                          <div className="row d-flex align-items-start justify-content-between">
                            <div className="col-md-10"></div>
                            <div className="col-md-2 d-flex justify-content-end mt-1">
                              {availableRooms < 1 ? (
                                <Button
                                  sx={{
                                    textWrap: "nowrap",
                                    border: "1px solid red",
                                    color: "red",
                                  }}
                                  onClick={openNoAvailableRoomDialog}
                                >
                                  Sold Out
                                </Button>
                              ) : (
                                <Link
                                  to={`/checkout?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.startDateTime}&checkout=${selection?.endDateTime}&person=${selection?.noOfAdult}&children=${selection?.noOfChild}&room=${selection?.noOfRooms}`}
                                  className="custom-small-btn text-nowrap"
                                  onClick={() => {
                                    sendIndex(roomIndex, index, i);

                                    sessionStorage.setItem(
                                      "checkoutString",
                                      `?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.startDateTime}&checkout=${selection?.endDateTime}&person=${selection?.noOfAdult}&children=${selection?.noOfChild}&room=${selection?.noOfRooms}`
                                    );
                                  }}
                                >
                                  Book Now
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <Dialog
        open={!isRoomAvailable}
        onClose={closeNoAvailableRoomDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <IconButton disableRipple sx={{ color: "orange", mr: 1 }}>
            <AlertTriangle size={24} />
          </IconButton>
          Room is not available!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary">
            No rooms are available for the selected date. Please try checking
            again later or modify your selection.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNoAvailableRoomDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RoomCard;
